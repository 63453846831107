import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const ContactPage = () => (
  <div>
    <SEO 
        title="Contact Sheep Hosting Bay" 
        description="If you can’t obtain the relevant information on the telephone or through the chat service, you have 
        available our contact form. The customer care crew watches it 24–7 and we’ll reply within one hour."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title">Contact <span className="block text-indigo-600 xl:inline">Sheephostingbay</span></p>
              <p className="section-after-title">
              Have a query? Want to submit a concern? <br/>We’d wish to know what you think.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="text-indigo-600">
                      <p>Our ID: 290832</p>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title">
              If you can’t obtain the relevant information on the telephone or through the chat service, you have 
              available our contact form. The customer care crew watches it 24–7 and we’ll reply within one hour. 
              Keep in mind, the more specifics you supply when you write to us, the easier it can be for us to 
              perform our job even sooner.
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/phones-and-chat.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Unique Cloud Hosting Platform</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        US Toll Free Phone: +1-855-211-0932<br/>
                        Int'l. Phone: +1-727-546-HOST(4678)<br/>
                        UK Phone: +44-20-3695-1294<br/>
                        AU Phone: +61-2-8417-2372<br/>
                        Our ID: 290832
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/working-hours.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">The Web Control Panel</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Monday to Friday:<br/>
                        09.00 AM - 11.00 PM GMT/UK<br/>
                        05.00 AM - 07.00 PM EST/USA<br/>
                        07.00 PM - 09.00 AM AEST/AU
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/server-status.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Server Status</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        It is easy to keep track of the situation of one’s server and look for recent problems at: 
                        <a href="https://properstatus.com/" target="_blank" rel="noreferrer">www.properstatus.com</a>
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default ContactPage
