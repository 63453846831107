import * as React from "react"

import Navbar from "../components/navbar"

const MenuPages = () => (
    <div>
        <Navbar/> 
    </div>
)


export default MenuPages
